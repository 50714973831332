import React from 'react'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import Br from '../system/Br'
import Button from '../system/Button'

export default function BuildBookTitle() {
  return (
    <Stack space={4}>
      <Row
        space={{ xs: 0, sm: 16 }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Button variant="underlineLink" href="/build/">
          <Box
            fontFamily="FactorA"
            fontWeight={700}
            letterSpacing="-0.02em"
            lineHeight="110%"
            css={`
              font-size: 82px;
              // Align text the left side
              margin-left: -6px;
            `}
          >
            Build
          </Box>
        </Button>

        <Box font="h3">
          Elements of an Effective <Br /> Software Organization
        </Box>
      </Row>

      <Box>
        By <Box.span fontWeight={700}>Rebecca Murphey</Box.span> and{' '}
        <Box.span fontWeight={700}>Otto Hilska</Box.span>
      </Box>
    </Stack>
  )
}
