import { useEffect, useState } from 'react'

const buildLocationKey = 'build_v2'
export function useBuildBookLocation() {
  return useLastLocation(buildLocationKey)
}

export function useStoreBuildBookLocation(currentHeadingId: string) {
  return useStoreLocation(buildLocationKey, currentHeadingId)
}

function getLocalStorageKey(key: string) {
  return 'use_last_location_' + key
}

export function useTrackCurrentHeading(path: string, headings = 'h2, h3, h4') {
  const [currentHeadingId, setCurrentHeadingId] = useState('')

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const options = {
      // -50% bottom margin = above first half of the screen
      rootMargin: '0px 0px -50% 0px',
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        const id = entry.target.getAttribute('id')
        if (id && entry.isIntersecting) {
          setCurrentHeadingId(id)
        }
      }
    }, options)
    document.querySelectorAll(headings).forEach(heading => {
      observer.observe(heading)
    })
    return () => observer.disconnect()
  }, [path, headings])

  return { currentHeadingId }
}

export function useStoreLocation(key: string, currentHeadingId: string) {
  const localStorageKey = getLocalStorageKey(key)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const hash = currentHeadingId ? '#' + currentHeadingId : ''

    window?.localStorage.setItem(
      localStorageKey,
      window.location.pathname + hash,
    )
  }, [
    localStorageKey,
    currentHeadingId,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to keep "window.location.pathname"
    typeof window !== 'undefined' && window?.location.pathname,
  ])
}

export function useLastLocation(key: string) {
  if (typeof window === 'undefined') {
    return
  }

  const localStorageKey = getLocalStorageKey(key)
  const lastPath = window?.localStorage.getItem(localStorageKey)
  return lastPath
}
