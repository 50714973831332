import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import MetaImage from '../assets/images/books/build-cover.jpg'
import LucaPhoto from '../assets/images/quotes/luca.jpg'
import { buildBookChapters, useBuyUrl } from '../bookUtils'
import { BookToc } from '../components/BookToc'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import BuildBookTitle from '../components/book/BuildBookTitle'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Button from '../components/system/Button'
import { useBuildBookLocation } from '../navigationUtils'
import theme from '../theme'

const BuildPage = () => {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "images/books/build-cover.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1000, quality: 90)
          original {
            src
            width
            height
          }
        }
      }
      chapters: allMdx(
        filter: { fileAbsolutePath: { regex: "/src/books/build/" } }
        sort: { order: ASC, fields: [slug] }
        limit: 1000
      ) {
        edges {
          node {
            id
            slug
            headings {
              value
              depth
            }
          }
        }
      }
    }
  `)

  const lastPath = useBuildBookLocation()
  const buyUrl = useBuyUrl()
  return (
    <Layout
      justifyCenter
      title="Build: Elements of an Effective Software Organization"
      description="Build: Elements of an Effective Software Organization is a blueprint for continuous improvement that zeroes in on three key ingredients: business outcomes, developer productivity, and developer experience."
      isNew
      hideNavigation
      metaImage={MetaImage}
    >
      <Block maxWidth="blog" paddingTop={{ xs: 24, lg: 48 }} paddingBottom={64}>
        <Button
          href="/"
          variant="ghost"
          size={{ xs: 'medium', lg: 'large' }}
          position={{ xs: 'static', lg: 'absolute' }}
          top={96}
          left={24}
          marginBottom={8}
          backgroundColor="white"
          zIndex={10}
        >
          ← &nbsp; Swarmia.com
        </Button>
        <CoverImage>
          <GatsbyImage image={getImage(data.cover)!} alt="Build book cover" />
        </CoverImage>
        <Stack space={32}>
          <BuildBookTitle />
          <Row
            space={{ xs: 16, sm: 24 }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Button
              variant="primary"
              size="large"
              href={buyUrl}
              target="_blank"
            >
              Buy the book
            </Button>

            {lastPath &&
            // Validate that the path has a sub-page "/build/something-here/"
            /\/build\/.*\//.test(lastPath) ? (
              <Button
                variant="secondary"
                backgroundColor="gray50"
                size="large"
                href={lastPath}
              >
                Continue where you left off
              </Button>
            ) : (
              <Button
                variant="secondary"
                backgroundColor="gray50"
                size="large"
                href="/build/00-preface"
              >
                Read online
              </Button>
            )}
          </Row>
          <Box>
            Building software is hard. Running an effective software engineering
            organization is harder.{' '}
            <Box.span fontStyle="italic">
              Build: Elements of an Effective Software Organization
            </Box.span>{' '}
            is a guide to help good software teams get better and remain
            effective as the organization grows and evolves.
          </Box>
          <Box>
            <Box.span fontStyle="italic">Build</Box.span> is a blueprint for
            continuous improvement. It zeroes in on three key ingredients: a
            relentless focus on{' '}
            <Box.span fontWeight={500}>business outcomes</Box.span>, actionable
            insights to boost the{' '}
            <Box.span fontWeight={500}>productivity</Box.span> of your software
            teams, and a thoughtful approach to improving the{' '}
            <Box.span fontWeight={500}>experience</Box.span> of building
            software at your company.
          </Box>
          <Box>
            Whether you’re a leader, a manager, or anyone invested in seeing
            your team or organization improve,{' '}
            <Box.span fontStyle="italic">Build</Box.span> provides the roadmap
            you need to drive meaningful, impactful progress.
          </Box>
        </Stack>
        <Box paddingTop={64} paddingBottom={32}>
          <Box
            borderColor="gray200"
            css={`
              border-width: 2px 0 0 0;
            `}
          />
        </Box>
        <Quote />
      </Block>
      <CTABlock
        title={
          <>
            Ready to explore how Swarmia can help you drive
            engineering&nbsp;effectiveness?
          </>
        }
      />
      <Box backgroundColor="blue50" paddingY={64}>
        <Block maxWidth="blog">
          <BookToc chapters={buildBookChapters(data.chapters)} />
        </Block>
      </Box>
    </Layout>
  )
}

export default BuildPage

const CoverImage = styled.div`
  margin: 0 -180px 32px -180px;

  .gatsby-image-wrapper {
    border-radius: 32px;
  }

  @media (max-width: 1140px) {
    margin: 0 -140px 48px -140px;
  }

  // This is when the sidebar layout collapses
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    margin: 0;
    margin-bottom: 16px;
    .gatsby-image-wrapper,
    img {
      border-radius: 12px;
    }
  }
`

const Quote = () => {
  return (
    <Row
      space={{ xs: 24, md: 48 }}
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'center', md: 'flex-start' }}
      textAlign={{ xs: 'center', md: 'left' }}
      position="relative"
    >
      <Box.img
        borderRadius={9999}
        loading="lazy"
        position={{ xs: 'static', md: 'absolute' }}
        left="-122px"
        width={90}
        height={90}
        src={LucaPhoto}
      />
      <Stack space={16}>
        <Box
          font="bookQuote"
          color="blue900"
          fontWeight={400}
          css={css`
            &::before {
              content: '“';
              width: 10px;
              margin-left: -10px;
              display: inline-block;
            }
            &::after {
              content: '”';
              width: 20px;
              /* Prevent flowing to second row*/
              margin-right: -20px;
              display: inline-block;
            }
          `}
        >
          The Build book has the rare virtue of exploring engineering
          effectiveness from a holistic perspective, rather than fixating on
          metrics and frameworks. It brings the kind of approach — team and
          outcome-based — that I believe we need today to move forward as an
          industry. I loved it!
        </Box>
        <Stack position="relative">
          <Box font="h4" fontWeight={500}>
            Luca Rossi
          </Box>
          <Box font="medium" color="black400">
            Founder, Refactoring
          </Box>
        </Stack>
      </Stack>
    </Row>
  )
}
