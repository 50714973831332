import styled from 'styled-components'
import Box from '../primitives/Box'

/**
 * This component is meant for making sure that the content does not reach
 * the edges of the screen on mobile.
 */
const Br = styled(Box.br)``

Br.defaultProps = {
  display: { xs: 'none', sm: 'inline' },
}

export default Br
