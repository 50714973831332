import React from 'react'
import { Chapter } from '../bookUtils'
import Box from './primitives/Box'
import Stack from './primitives/Stack'
import Button from './system/Button'

export function BookToc({
  chapters,
  onLinkClick,
}: {
  chapters: Chapter[]
  onLinkClick?: () => void
}) {
  return (
    <Stack space={36}>
      {chapters.map(chapter => (
        <Box key={chapter.path}>
          <Box>
            <Button
              variant="underlineLink"
              letterSpacing="-0.02em"
              href={chapter.path}
              font="normal"
              fontWeight={700}
              marginBottom={16}
              textTransform="uppercase"
              onClick={onLinkClick}
            >
              {chapter.heading}
            </Button>
          </Box>

          <Stack space={4} paddingLeft={0}>
            {chapter.children.map(child => (
              <Button
                key={child.path}
                href={child.path}
                variant="underlineLink"
                font="large"
                paddingBottom={4}
                onClick={onLinkClick}
              >
                {child.heading}
              </Button>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}
